import 'bootstrap'
import { initRailsLibs } from 'src/shared/init'
import backToTopBtn from 'src/public/back-to-top-btn'
import stickyMenu from 'src/public/sticky-menu'

import '@fortawesome/fontawesome-free/js/all'

import 'stylesheets/public.scss'

initRailsLibs()

function toggleMenu() {
  const link = document.getElementById('main_menu_toggle')
  if (!link) {
    return
  }
  link.addEventListener('click', function() {
    link.classList.toggle('open')
    const isOpen = document.getElementById('navigation')
    if (isOpen.style.display === 'block') {
      isOpen.style.display = 'none'
    } else {
      isOpen.style.display = 'block'
    }
  })
}

window.onload = function() {
  stickyMenu()
  backToTopBtn()
  toggleMenu()
}
