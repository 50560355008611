import Rails from '@rails/ujs'

require.context('../../images', true)

const initRailsLibs = function() {
  Rails.start()
  return Rails
}

export { initRailsLibs, Rails }
