const stickyMenu = () => {
  const navbar = document.getElementById('topnav')
  if (navbar != null) {
    document.addEventListener('scroll', function() {
      if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
        navbar.classList.add('nav-sticky')
      } else {
        navbar.classList.remove('nav-sticky')
      }
    })
  }
}

export default stickyMenu
