function backToTopBtn() {
  const el = document.getElementById('back-to-top')
  if (el == null) return

  document.addEventListener('scroll', () => {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      el.style.display = 'block'
    } else {
      el.style.display = 'none'
    }
  })

  el.addEventListener('click', (e) => {
    e.preventDefault()
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })
}

export default backToTopBtn
